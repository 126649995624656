<template>
    <div class="login-container" @keypress.enter="login">
        <div class="login-container-bg">
            <div class="form-wrapper">
                <div class="header">
                    管理平台
                </div>
                <div class="input-wrapper">
                    <div class="border-wrapper">
                        <div class="border-item">
                            <input type="text" v-model="mobile" name="mobile" placeholder="请输入手机号" class="border-item-ipt full-width" autocomplete="off" />
                        </div>
                    </div>
                    <div class="border-wrapper">
                        <div class="border-item">
                            <input type="text" v-model="code" name="code" placeholder="请输入验证码" class="border-item-ipt" autocomplete="off" />
                            <div @click="onSend" class="btn-plain font-primary font-midium width100">获取验证码</div>
                        </div>
                    </div>
                </div>
                <div class="action">
                    <div class="btn" @click="login">登录</div>
                </div>
                <!-- <div class="icon-wrapper"></div> -->
            </div>
        </div>
    </div>
</template>
<script>
    import LoginService from './components/service/LoginService';
    export default({
        components:{

        },
        data() {
            return {
                mobile: "15959047927",
                code: "666666",
                service: new LoginService(this)
            };
        },
        mounted() {

        },
        methods: {
            onSend() {
                if(!this.mobile) {
                    this.$message.error("请输入登录手机号");
                    return;
                }
                this.service.send(this.mobile).then(response=>{
                    if(response.code == "200") {
                        this.$message({
							message: "验证码发送成功",
							type: 'success'
						});
                    }
                });
            },
            login() {
                if(!this.mobile) {
                    this.$message.error("请输入登录手机号");
                    return;
                }
                if(!this.code) {
                    this.$message.error("请输入验证码");
                    return;
                }
                console.log(this.username+", "+this.password);
                this.service.loginByCode({"mobile":this.mobile, "code": this.code}).then(response=>{
                    if(response.code == "200") {
                        this.$message({
							message: response.msg,
							type: 'success'
						});
                        this.$router.push({
                            path: "/index"
                        })
                    }
                });
                // HttpUtils.POST("/api/v0.0.1/login", {"mobile":this.username, "password":this.password}).then(response=>{
				// 	console.log(response);
				// 	if(response.code == "200") {
				// 		this.$message({
				// 			message: response.msg,
				// 			type: 'success'
				// 		});
				// 		this.$router.push({
                //             path: "/home"
                //         });
				// 	} else {
                //         let msg = response.msg;
                //         if(response.data) {
                //             msg = msg + response.data;
                //         }
				// 		this.$message.error(msg);
				// 	}
				// });
                
            }
        }
    });
</script>
<style lang="scss" scoped>
    * {
        padding: 0;
        margin: 0;
    }
    .login-container {
        overflow: hidden;
        background-color: #F1F1F1;
    }
    .login-container-bg {
        overflow: hidden;
        width: 100vw;
        height: 100vh;
        //background: url(../assets/bg-science.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .form-wrapper {
        margin: 0 auto;
        width: 300px;
        background-color: #E9E9E9;
        color: #000;
        border-radius: 2px;
        padding: 50px;
        box-shadow: 0 0 5px 5px #E1E1E1 !important;
    }
    .form-wrapper .header {
        text-align: center;
        font-size: 35px;
        text-transform: uppercase;
        line-height: 100px;
    }
    .form-wrapper .input-wrapper input {
        background-color: rgb(255, 255, 255);
        border: 0;
        width: 90%;
        text-align: left;
        font-size: 15px;
        color: #000;
        outline: none;
        padding: 0px 20px;
    }
    .full-width {
        width: 100%!important;
    }
    .form-wrapper .input-wrapper input::placeholder {
        text-transform: uppercase;
        font-size: 13px;
        text-align: left;
    }
    .form-wrapper .input-wrapper .border-wrapper {
        background-image: linear-gradient(to right, #e8198b, #0eb4dd);
        width: 100%;
        height: 50px;
        margin-bottom: 20px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .form-wrapper .input-wrapper .border-wrapper .border-item {
        height: calc(100% - 4px);
        width: calc(100% - 4px);
        border-radius: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
    }
    .form-wrapper .input-wrapper .border-wrapper .border-item .border-item-ipt {
        background-color: rgb(255, 255, 255);
        border: 0;
        border-radius: 30px;
        height: 100%;
        width: 60%;
        text-align: left;
        font-size: 15px;
        color: #000;
        outline: none;
    }
    .form-wrapper .input-wrapper .border-wrapper .border-item .btn-plain {
        border: 0;
        height: 100%;
        text-align: center;
        line-height: 50px;
        padding: 5px;
        outline: none;
    }
    .width100 {
        width: 100px;
    }
    .width150 {
        width: 150px;
    }
    .width200 {
        width: 200px;
    }
    .font-primary {
        color: #409EFF;
    }
    .font-small {
        font-size: 13px;
    }
    .font-midium {
        font-size: 15px;
    }
    .form-wrapper .action {
        display: flex;
        justify-content: center;
    }
    .form-wrapper .action .btn {
        width: 60%;
        text-transform: uppercase;
        border: 2px solid #0e92b3;
        background-image: linear-gradient(120deg, #8fd3f4 0%, #84fab0 100%);
        text-align: center;
        line-height: 50px;
        border-radius: 30px;
        cursor: pointer;
        color: #fff;
        font-size: 18px;
    }
    .form-wrapper .action .btn:hover {
        background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
    }
    .form-wrapper .icon-wrapper {
        text-align: center;
        width: 60%;
        margin: 0 auto;
        margin-top: 20px;
        border-top: 1px dashed rgb(146, 146, 146);
        padding: 20px;
    }
    .form-wrapper .icon-wrapper {
        font-size: 20px;
        color: rgb(187, 187, 187);
        cursor: pointer;
        border: 1px solid #fff;
        padding: 5px;
        border-radius: 20px;
    }
    .form-wrapper .icon-wrapper i:hover {
        background-color: #0e92b3;
    }
</style>