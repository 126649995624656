<template>
	<el-row class="body-container">
		<el-col :span="leftNavSpan" class="dark-bg left-nav">
			<div class="left-nav-header">
				<div v-show="!isCollapse" style="display: flex; flex-direction: row; justify-content: center;">
					<el-avatar :size="40" :src="avatarUrl"></el-avatar>
					<div class="username">欢迎您，管理员</div>
				</div>
				<div v-show="isCollapse">
					<el-avatar :size="30" :src="avatarUrl"></el-avatar>
				</div>
			</div>
			<div class="line-sm"></div>
            <el-scrollbar style="height: 94%;">
				<el-menu default-active="1" background-color="#263544" text-color="#fff" active-text-color="#ffd04b"
					mode="vertical" :collapse="isCollapse">
					<template v-for="(menu, index) in menus">
						<LeftNavMenu ref="leftNavMenu" :menu="menu"></LeftNavMenu>
					</template>
				</el-menu>
			</el-scrollbar>
		</el-col>
		<el-col :span="rightNavSpan">
			<div class="header-menu">
				<div style="flex: 1" class="item-list float-left">
					<div class="item" @click="handleCollapse">
						<el-icon :size="20" v-if="!isCollapse"><Fold /></el-icon>
						<el-icon :size="20" v-if="isCollapse"><Expand /></el-icon>
						<!-- <i :class="collapseIcon"></i> -->
					</div>
					<div class="item">
						<!-- <i class="el-icon-menu" title="标签"></i> -->
						<el-icon size="20">
							<Menu />
						</el-icon>
					</div>
				</div>
				<div style="flex: 10" class="item-list float-left">
				</div>
				<div style="flex: 2; margin-right: 10px;" class="item-list float-center">
					<div class="item">
						<el-icon><Bell /></el-icon>
					</div>
					<div class="item">
						<el-icon><ChatDotRound /></el-icon>
					</div>
					<div class="item">
						<el-icon><Message /></el-icon>
					</div>
					<div class="item">
						<el-icon><Refresh /></el-icon>
					</div>
					<el-popover
						placement="bottom"
						width="250px"
						trigger="hover" :visible="visible">
						<div class="user-info-pop" @mouseenter="showPop" @mouseleave="closePop">
							<div class="user-info-body">
								<el-row>
									<el-col :span="8">UID:</el-col>
									<el-col :span="16">{{ userInfo.uid }}</el-col>
								</el-row>
								<div class="line-sm"></div>
								<el-row>
									<el-col :span="8">昵称:</el-col>
									<el-col :span="16">{{ userInfo.name }}</el-col>
								</el-row>
								<div class="line-sm"></div>
								<el-row>
									<el-col :span="8">电话:</el-col>
									<el-col :span="16">{{ userInfo.mobile }}</el-col>
								</el-row>
								<div class="line-sm"></div>
								<el-row>
									<el-col :span="8">等级:</el-col>
									<el-col :span="16">{{ userInfo.level }}</el-col>
								</el-row>
							</div>
							<div class="line-sm"></div>
							<div class="user-info-footer">
								<el-button @click="handleUpdatePwd" size="small" type="primary">修改密码</el-button>
								<el-button @click="logout" size="small" type="danger">退出</el-button>
							</div>
						</div>
						<template #reference>
							<div @mouseenter="showPop" @mouseleave="closePop" class="item" style="display: flex; flex-direction: row; justify-content: flex-start; width: 100px;">
								<div class="avatar">
									<el-avatar shape="square" :size="40" :src="avatarUrl"></el-avatar>
								</div>
								<div class="username" style="font-size: 14px;">
									{{ userInfo.name }}
								</div>
							</div>
						</template>
					</el-popover>
				</div>
			</div>
			<div class="line-xxm"></div>
			<div class="main-box">
				<el-col class="main-box-panel" :span="24">
					<el-tabs style="height: 100%;" v-model="activeTabIdx" @tab-remove="handleTabRemove">
						<el-tab-pane key="home" label="主页" :closable="false" name="0">
							<template v-slot:label><el-icon><House /></el-icon>主页</template>
							<div class="tab-container">
								<home></home>
							</div>
						</el-tab-pane>
						<el-tab-pane
							:key="item.name"
							v-for="item in tabs"
							:label="item.title"
							:name="item.name"
							:closable="item.closable"
						>
							<template v-slot:label>
								<el-icon size="16" style="height: 20px; line-height: 20px;">
									<component :is="item.icon"></component>
								</el-icon>
								{{item.title?item.title:""}}
							</template>
							<div class="tab-container">
								<component :is="item.component"></component>
							</div>
						</el-tab-pane>
					</el-tabs>
				</el-col>
			</div>
		</el-col>
	</el-row>

</template>

<script>

import LeftNavMenu from "@/components/LeftNavMenu.vue"
//vue异步加载的组件，没有这个，将无法加载异步的组件
import { defineAsyncComponent } from 'vue'
import Home from './Home.vue'
export default {
	name: 'Index',
	components: {
		LeftNavMenu, Home
	},
	created() {
		this.height = window.innerHeight - 70;
	},
	data() {
		return {
			userInfo: {
				"uid": "00001",
				"name": "测试",
				"mobile": "13800000000",
				"level": 1
			},
			dialogFormVisible: false,
			form: {},
			visible: false,
			height: 800,
			activeTabIdx: "0",
			isCollapse: false,
			leftNavSpan: 3,
			rightNavSpan: 21,
			collapseIcon: "el-icon-s-fold",
			avatarUrl: "https://demo.dashboardpack.com/admindek-html/files/assets/images/avatar-4.jpg",
			tabs:[],
			menus: []
		}
	},
	provide: function() {
		return {
			addTab: this.addTab
		}
	},
	mounted() {
		this.initMenus(true);
	},
	methods: {
		//权限暂时先写死
		initMenus(isAdmin) {
			let menus = [{}, {
				name: "食堂管理",
				uid:1,
				icon: "KnifeFork",
				isShow: true,
				children:[{
					name: "餐别管理",
					uid:11,
					icon: "Dish",
					url:"/canteen/mealType/MealTypeView.vue",
					component: "MealTypeView",
					isShow: true	//权限暂时写死
				}, {
					name: "菜品管理",
					uid:12,
					icon: "Food",
					url:"/canteen/food/FoodInfoView.vue",
					component: "FoodInfoView",
					isShow: true	//权限暂时写死
				}, {
					name: "APP用户管理",
					uid:13,
					icon: "User",
					url:"/accountAppInfo/AccountAppInfoView.vue",
					component: "AccountAppInfoView",
					isShow: true	//权限暂时写死
				}, {
					name: "订单管理",
					uid:14,
					icon: "PieChart",
					url:"/order/OrderInfoView.vue",
					component: "OrderInfoView",
					isShow: true	//权限暂时写死
				}]
			}, {
				name: "系统配置",
				uid: 2,
				icon: "Menu",
				isShow: true,
				children: [{
					name: "选项2",
					uid:22,
					icon: "el-icon-location"
				}, {
					name: "选项3",
					uid:23,
					icon: "el-icon-location"
				}, {
					name: "选项4",
					uid:24,
					icon: "el-icon-location"
				}, {
					name: "选项5",
					uid:25,
					icon: "el-icon-location"
				}, {
					name: "选项6",
					uid:26,
					icon: "el-icon-location"
				}, {
					name: "选项7",
					uid:27,
					icon: "el-icon-location"
				}, {
					name: "选项8",
					uid:28,
					icon: "el-icon-location"
				}, {
					name: "选项9",
					uid:29,
					icon: "el-icon-location"
				}, {
					name: "选项10",
					uid:210,
					icon: "el-icon-location"
				}, {
					name: "选项11",
					uid:211,
					icon: "el-icon-location"
				}, {
					name: "选项12",
					uid:212,
					icon: "el-icon-location"
				}]
			}, {
				name: "导航三",
				uid: 3,
				icon: "el-icon-document",
				children: []
			}, {
				name: "导航四",
				uid: 4,
				icon: "el-icon-setting",
				children: []
			}, {
				name: "导航五",
				uid: 5,
				icon: "el-icon-document",
				children: []
			}]
			this.menus = menus;
		},
		handleCollapse() {
			this.isCollapse = !this.isCollapse;
			if (this.isCollapse) {
				this.leftNavSpan = 1;
				this.rightNavSpan = 23;
				this.collapseIcon = "el-icon-s-unfold";
			} else {
				this.leftNavSpan = 3;
				this.rightNavSpan = 21;
				this.collapseIcon = "el-icon-s-fold";
			}
			console.log(this.$refs.leftNavMenu);
			// this.$refs.leftNavMenu.$emit("setIsCollapse", this.isCollapse);
		},
		addTab(menu) {
			var tab = null;
			for(var idx in this.tabs) {
				var existTab = this.tabs[idx];
				if(existTab.name == menu.uid) {
					tab = existTab;
					break;
				}
			}
			if(!tab) {
				console.log(menu.url);
				// var component = () => import("./views/"+menu.url);
				// var component = ()=>import("./views/approbot/order-task/OrderTaskList.vue");
				//上面的方式不能用了，需要用下面这种方式来实现异步加载
				var component = defineAsyncComponent(() =>
					import("@/views"+menu.url)
				);
				console.log(component);
				this.$options.components[menu.component] = component;
				console.log(this.$options.components[menu.component]);
				tab = {
					title: menu.name,
					icon: menu.icon,
					name: menu.uid,
					component: component,
					closable: true
				}
				this.tabs.push(tab);
			}
			this.activeTabIdx = tab.name;
		},
		//name为被删除的tab的name
		handleTabRemove(name) {
			//console.log("删除tab为"+name+"的标签");
			for(var idx in this.tabs) {
				var tab = this.tabs[idx];
				if(tab.name == name) {
					this.tabs.splice(idx, 1);
					if(this.activeTabIdx == name) {
						if(this.tabs[idx]) {
							this.activeTabIdx = this.tabs[idx].name;
						} else if(this.tabs[idx-1]) {
							this.activeTabIdx = this.tabs[idx-1].name;
						} else {
							this.activeTabIdx = "0";
						}
						// this.activeTabIdx = this.tabs[idx]?this.tabs[idx].name:this.tabs[idx-1].name;
					}
					break;
				}
			}
		},
		showPop() {
			this.visible = true;
		},
		closePop() {
			this.visible = false;
		},
		logout() {
			store.commit('setToken', "");
			window.localStorage.setItem('token', "");
			window.location.href = "/login";
		},
		handleUpdatePwd() {
			this.form = {};
			this.dialogFormVisible = true;
		}
	}
}
</script>

<style lang="scss">
	body {
		overflow: hidden;
		margin: 0px;
	}
    //滚动条整体部分
    *::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    //滚动条轨道两端按钮，允许通过点击微调小方块的位置
    *::-webkit-scrollbar-button {
        display: none;
    }
    //滚动条里面的小方块，能向上向下移动（或左右移动）
    *::-webkit-scrollbar-thumb {
        background: rgba(144,147,153,0.3);
        cursor: pointer;
        border-radius: 4px;
    }
    //两个滚动条交汇处的边角
    *::-webkit-scrollbar-corner {
        display: none;
    }
    //两个滚动条交汇处用于通过拖动调整元素大小的小控件
    *::-webkit-resizer {
        display: none;
    }

	.body-container {
		background-color: #f1f1f1;
		// background-color: #fff;
	}

	.dark-bg {
		background-color: #263544 !important;
		color: #fff;
	}

	.el-submenu .el-menu-item {
		min-width: 0 !important;
	}

	.el-menu {
		text-align: left !important;
		border-right: 0px !important;
		margin-right: 6px;
		box-sizing: border-box;
	}

	.left-nav {
		height: calc(100vh);
		overflow-x: hidden;
		overflow-y: hidden;
		transition-property: max-width, flex;
		transition-duration: .25s, .25s;
		transition-timing-function: ease;
		box-shadow: 0 2px 6px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .22) !important;
		// box-shadow: 0 1px 4px rgb(0 21 41 / 8%);

		.left-nav-header {
			height: 40px;
			padding: 8px;
			line-height: 40px;
			display: flex;
			justify-content: center;

			div {
				display: flex;
				flex-direction: row;
				justify-content: space-around;

				.username {
					margin-left: 10px;
				}
			}
		}

		.line-sm {
			height: 1px;
			transform-origin: 0 0;
			transform: scaleY(.1);
			border-bottom: 1px solid #dee2e6;
		}

		.el-avatar {
			border: 2px solid #fff;
		}
	}

	.line-sm {
		height: 1px;
		transform-origin: 0 0;
		transform: scaleY(.1);
		border-bottom: 1px solid #dee2e6;
	}

	.line-xm {
		height: 1px;
		transform-origin: 0 0;
		transform: scaleY(.05);
		border-bottom: 1px solid #dee2e6;
	}

	.line-xxm {
		height: 1px;
		transform-origin: 0 0;
		transform: scaleY(.01);
		border-bottom: 1px solid #dee2e6;
	}

	.header-menu {
		background-color: #fff;
		// border-bottom: 1px solid #dee2e6;
		// box-shadow: 0 2px 4px 0 rgb(43 43 43 / 10%);
		height: 56px !important;
		line-height: 56px !important;
		display: flex;
		flex-direction: row;
		border: 0px;
		.item-list {
			display: flex;
			flex-direction: row;

			.item {
				cursor: pointer;
				min-width: 50px;
				text-align: center;
			}

			.item .avatar {
				padding: 8px;
			}

			.el-breadcrumb {
				line-height: 58px !important;
				font-size: 16px;
				letter-spacing: 2px;

				.el-breadcrumb__separator {
					margin: 0px 15px;
				}
			}
		}
	}

	.float-center {
		justify-content: center;
	}

	.float-left {
		justify-content: flex-start;
	}

	.float-right {
		justify-content: flex-end;
	}
	.main-box {
		.main-box-panel {
			height: calc(100vh);
			width: calc(100vw);
			// border: 1px solid #dee2e6;
			// border-radius: 5px;
			// height: calc(100vh);
			// box-shadow: 0px 0px 4px 3px rgb(43 43 43 / 10%);	//0px 0px表示四周都有阴影
		}
	}
	.el-tabs__header {
		background-color: #fff!important;
		border-bottom: 1px solid #dee2e6!important;
		// box-shadow: 0 1px 2px 0 rgb(43 43 43 / 10%)!important;
		box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
		padding: 0px 20px!important;
		margin-bottom: 0px!important;
	}
	.el-tabs__content {
		height: calc(87vh);
		box-sizing: border-box;
		// padding: 10px;
		overflow-y: auto!important;
	}
	.el-tab-pane {
		height: 100%;
	}
	.tab-container {
		padding: 10px 10px 10px 10px;
	}
	.tab-container > *:first-child {
		box-shadow: 0 0 5px 5px #E1E1E1;
		border-radius: 5px;
		padding: 5px;
		background: #fff;
	}
	.user-info-pop {
		width: 100%;
		box-sizing: border-box;
	}
	.user-info-body {
		width: 100%;
		margin-bottom: 10px;
	}
	.user-info-footer {
		margin-top: 10px;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}
	.user-info-body .el-row .el-col{
		line-height: 25px!important;
		font-size: 12px!important;
		padding: 5px;
	}
	.user-info-body .el-row .el-col:nth-child(odd) {
		text-align: right!important;
	}
	.user-info-body .el-row .el-col:nth-child(even) {
		text-align: left!important;
	}
</style>
