<template>
    <div style="border: 0px; height: 600px; background-color: transparent; box-shadow: 0 0 0px 0px #E1E1E1;">
        <div class="row row-space-between">
            <el-card style="width: 48%; height: 200px;">
                <template #header>
                    <div class="row row-start" style="height: 30px; line-height: 30px;">
                        <div>
                            <span>今日数据</span>
                        </div>
                        <div class="column column-center column-hcenter" style="width: 30px; height: 30px; line-height: 30px;">
                            <el-icon :size="20"><Odometer /></el-icon>
                        </div>
                    </div>
                </template>
                <el-row>
                    <el-col :span="8">
                        <div class="row row-center row-vcenter full-width">
                            <el-space :size="20">
                                <div class="icon half-width row row-center row-vcenter">
                                    <el-icon size="30" color="#409EFF"><ShoppingCartFull /></el-icon>
                                </div>
                                <div class="data-box column column-center column-hcenter half-width">
                                    <div class="data-box-header font-bold">今日订单</div>
                                    <div class="data-box-content">{{ todayOrderCnt }}</div>
                                </div>
                            </el-space>
                        </div>
                    </el-col>

                    <el-col :span="8">
                        <div class="row row-center row-vcenter full-width">
                            <el-space :size="20">
                                <div class="icon half-width row row-center row-vcenter">
                                    <el-icon size="30" color="#F56C6C"><Coin /></el-icon>
                                </div>
                                <div class="data-box column column-center column-hcenter half-width">
                                    <div class="data-box-header font-bold">今日收入</div>
                                    <div class="data-box-content">{{ todayTotalAmount }}</div>
                                </div>
                            </el-space>
                        </div>
                    </el-col>

                    <el-col :span="8">
                        <div class="row row-center row-vcenter full-width">
                            <el-space :size="20">
                                <div class="icon half-width row row-center row-vcenter">
                                    <el-icon size="30" color="#67C23A"><Wallet /></el-icon>
                                </div>
                                <div class="data-box column column-center column-hcenter half-width">
                                    <div class="data-box-header font-bold">今日充值</div>
                                    <div class="data-box-content">{{ todayTotalRecharge }}</div>
                                </div>
                            </el-space>
                        </div>
                    </el-col>
                </el-row>
            </el-card>

            <el-card style="width: 48%; height: 200px;">
                <template #header>
                    <div class="row row-start" style="height: 30px; line-height: 30px;">
                        <div>
                            <span>本月数据</span>
                        </div>
                        <div class="column column-center column-hcenter" style="width: 30px; height: 30px; line-height: 30px;">
                            <el-icon :size="20"><Odometer /></el-icon>
                        </div>
                    </div>
                </template>
                <el-row>
                    <el-col :span="8">
                        <div class="row row-center row-vcenter full-width">
                            <el-space :size="20">
                                <div class="icon half-width row row-center row-vcenter">
                                    <el-icon size="30" color="#409EFF"><ShoppingCartFull /></el-icon>
                                </div>
                                <div class="data-box column column-center column-hcenter half-width">
                                    <div class="data-box-header font-bold">本月总订单</div>
                                    <div class="data-box-content">{{ monthOrderCnt }}</div>
                                </div>
                            </el-space>
                        </div>
                    </el-col>

                    <el-col :span="8">
                        <div class="row row-center row-vcenter full-width">
                            <el-space :size="20">
                                <div class="icon half-width row row-center row-vcenter">
                                    <el-icon size="30" color="#F56C6C"><Coin /></el-icon>
                                </div>
                                <div class="data-box column column-center column-hcenter half-width">
                                    <div class="data-box-header font-bold">本月总收入</div>
                                    <div class="data-box-content">{{ monthTotalAmount }}</div>
                                </div>
                            </el-space>
                        </div>
                    </el-col>

                    <el-col :span="8">
                        <div class="row row-center row-vcenter full-width">
                            <el-space :size="20">
                                <div class="icon half-width row row-center row-vcenter">
                                    <el-icon size="30" color="#67C23A"><Wallet /></el-icon>
                                </div>
                                <div class="data-box column column-center column-hcenter half-width">
                                    <div class="data-box-header font-bold">本月充值</div>
                                    <div class="data-box-content">{{ monthTotalRecharge }}</div>
                                </div>
                            </el-space>
                        </div>
                    </el-col>
                </el-row>
            </el-card>
        </div>
    </div>
</template>
<script>
import DateUtils from "@/components/utils/DateUtils";
import MoneyUtils from "@/components/utils/MoneyUtils";
import HomeService from "@/components/service/HomeService";
export default {
    data() {
        return {
            todayOrderCnt: 0,
            todayTotalAmount: 0,
            todayTotalRecharge: 0,
            monthOrderCnt: 0,
            monthTotalAmount: 0,
            monthTotalRecharge: 0,
            service: new HomeService(this)
        }
    },
    mounted() {
        this.getTodayStatisticData();
    },
    methods: {
        formatDate(date) {
            return DateUtils.FORMAT_SHORT(date);
        },
        toYuan(money) {
            return MoneyUtils.TO_YUAN(money);
        },
        getTodayStatisticData() {
            this.service.getTodayStatisticData().then(response=>{
                this.todayOrderCnt = response.data.todayOrderCnt;
                this.todayTotalAmount = this.toYuan(response.data.todayTotalAmount);
                this.todayTotalRecharge = this.toYuan(response.data.todayTotalRecharge);
                this.monthOrderCnt = response.data.monthOrderCnt;
                this.monthTotalAmount = this.toYuan(response.data.monthTotalAmount);
                this.monthTotalRecharge = this.toYuan(response.data.monthTotalRecharge);
            })
        }
    },
}
</script>
<style lang="scss" scoped>
    .data-box {
        height: 100px;
        .data-box-header {
            height: 50px;
            line-height: 50px;
        }
        .data-box-content {
            height: 50px;
            line-height: 50px;
        }
    }
</style>