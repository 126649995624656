import { createRouter, createWebHistory } from 'vue-router'
import Index from "../views/Index.vue"
import Login from "../Login.vue"

const routes = [
  {
    path: "/index",
    name: "index",
    component: Index,
    meta: {
			title: '管理平台',
			keepAlive: false
		}
  },
  {
    path: "/",
    name: "",
    component: Login,
    meta: {
			title: '管理平台',
			keepAlive: false
		}
  },
  {
    path: "/login",
    name: "",
    component: Login,
    meta: {
			title: '管理平台',
			keepAlive: false
		}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
})

export default router
