<template>
    <el-sub-menu v-if="menu.isShow && menu.children && menu.children.length > 0" :index="menu.uid+''">
        <template v-slot:title>
            <el-icon size="16">
                <component :is="menu.icon"></component>
            </el-icon>
            <span slot="title">{{menu.name}}</span>
        </template>
        <template v-for="child in menu.children">
            <LeftNavMenu :menu="child"></LeftNavMenu>
        </template>
    </el-sub-menu>
    <el-menu-item v-else-if="menu.isShow" @click="addTab(menu)" :index="menu.uid+''">
        <el-icon size="16" style="height: 20px; line-height: 20px;">
            <component :is="menu.icon"></component>
        </el-icon>
        <span slot="title" style="margin-left: 5px;height: 20px; line-height: 20px;">{{menu.name}}</span>
    </el-menu-item>
</template>
<script>
export default {
    name: "LeftNavMenu",
    props:["menu"],
    inject: ["addTab"],
    data() {
        return {
            isCollapse: false
        }
    },
    methods: {
        setIsCollapse(isCollapse) {
            this.isCollapse = isCollapse;
        }
    }
}
</script>

