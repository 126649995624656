import { _ } from 'core-js';
import BaseService from "./BaseService";
import HttpUtils from "@/components/utils/HttpUtils";

export default class LoginService extends BaseService {
    url = "api/v0.0.1/login";
    handleParam() {

    }
    send(mobile) {
        console.log("send", mobile);
        return HttpUtils.GET(this.url+"/send/"+mobile, {});
    }
    loginByCode(form) {
        console.log("loginByCode", form);
        return HttpUtils.POST(this.url+"/loginByCode", form);
    }
}