import Constants from "@/components/constants/Constants";
import HttpUtils from "@/components/utils/HttpUtils";
export default class BaseService {
    view = null;
    url = "";
    constructor(view) {
        this.view = view;
    }
    refreshGrid(data) {
        //console.log(data);
        this.view.list = data.rows;
        this.view.total = data.total;
        this.view.currentPage = data.pageNum;
    }
    selectPage(callback) {
        this.view.paramMap.pageNum = this.view.paramMap.pageNum ? this.view.paramMap.pageNum : Constants.INIT_PAGE_NUM;
        this.view.paramMap.pageSize = this.view.paramMap.pageSize ? this.view.paramMap.pageSize : Constants.INIT_PAGE_SIZE;
        HttpUtils.POST(this.url+"queryPage", this.view.paramMap).then(response=>{
            if(null == this.view) {
                return;
            }
            console.log(response.data);
            this.refreshGrid(response.data);
            if(callback) {
                callback(response);
            }
        });
    }
    saveCallback(data) {
        if(null == this.view) {
            return;
        }
        var msg = data.msg;
        var type = "success";
        if(data.code == 1) {
            this.view.queryPage();
            this.view.dialog = false;
        } else {
            type = "error";
        }
        
        this.view.$message({
            message: msg,
            type: type,
            showClose: true
        });
    }
    insert(form) {
        HttpUtils.POST(this.url+"add", form).then(response=>{
            this.saveCallback(response);
        }).catch(error=> {

        });
    }
    update(form) {
        HttpUtils.POST(this.url+"update", form).then(response=>{
            this.saveCallback(response);
        }).catch(error=> {

        });
    }
    save() {
        console.log(this.view.form);
        if(!this.view.form.uid) {
            this.insert(this.view.form);
        } else {
            this.update(this.view.form);
        }
    }
    queryById(id, callback) {
        var paramMap = {"uid": id};
        HttpUtils.POST(this.url+"queryById", paramMap).then(response=>{
            callback(response);
        }).catch(error=> {

        });
    }
    deleteById(id) {
        var paramMap = {"uid": id};
        HttpUtils.POST(this.url+"delete", paramMap).then(response=>{
            console.log(response.code);
            if(response.code==1) {
                console.log("删除成功");
                this.selectPage();
                console.log("刷新成功");
                this.view.$message({
                  message: "删除成功",
                  type: "success",
                  showClose: true
                });
            } else {
                this.view.$message({
                    message: response.msg,
                    type: "error",
                    showClose: true
                });
            }
        }).catch(error=> {

        });
    }
    handleEdit(id, callback) {
        var thiz = this;
        this.queryById(id, function(response) {
            if(response.code == 1) {
                if(callback) {
                    callback(response);
                }
                console.log(response.data);
                thiz.view.form = response.data;
                thiz.view.dialog = true;
            } else {
                var msg = response.msg;
                thiz.view.$message({
                    message: msg,
                    type: "error",
                    showClose: true
                });
            }
        });
    }
    handleDel(id) {
        var thiz = this;
        this.view.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: false,
            callback: function(action, instance) {
              if(action == "confirm") {
                thiz.deleteById(id);
              } else {
                console.log("已取消");
              }
            }
        });
    }
    handleParam() {
        
    }
}