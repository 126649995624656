export default class DateUtils {
    static FORMAT_SHORT(date) {
        if(!date) {
            return "";
        }
        var type = Object.prototype.toString.call(date);
        if(type == "[object String]") {
            date = DateUtils.PARSE_DATE(date);
        } else if(type == "[object Number]") {
            date = DateUtils.PARSE_DATE(date);
        }
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        month = month < 10 ? "0"+month:month;
        var day = date.getDate();
        day = day < 10 ? "0"+day : day;
        return year + "-" + month + "-" + day;
    }
    static FORMAT_FULL(date) {
        if(!date) {
            return "";
        }
        var type = Object.prototype.toString.call(date);
        if(type == "[object String]") {
            date = DateUtils.PARSE_DATE(date);
        } else if(type == "[object Number]") {
            date = DateUtils.PARSE_DATE(date);
        }
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        month = month < 10 ? "0"+month:month;
        var day = date.getDate();
        day = day < 10 ? "0"+day : day;
        var hour = date.getHours();                   //小时 
        hour = hour < 10 ? "0"+hour : hour;
        var min = date.getMinutes();                 //分 
        min = min < 10 ? "0"+min : min;
        var sec = date.getSeconds();
        sec = sec < 10 ? "0"+sec : sec;
        return year + "-" + month + "-" + day + " "+hour+":"+min+":"+sec;
    }
    /**
     * 格式化时间，并设置成一天中最开始的时间，即00:00:00
     */
    static FORMAT_DATETIME_BEGIN(date) {
        date = DateUtils.FORMAT_SHORT(date);
        if(!date) {
            return date;
        }
        return date + " 00:00:00";
    }
    /**
     * 格式化时间，并设置成一天中结束的时间，即23:59:59
     */
    static FORMAT_DATETIME_END(date) {
        date = DateUtils.FORMAT_SHORT(date);
        if(!date) {
            return date;
        }
        return date + " 23:59:59";
    }
    static PARSE_DATE(dateStr) {
        var date = new Date(dateStr);
        return date;
    }
}