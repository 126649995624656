import axios from 'axios';
import { _ } from 'core-js';
import store from '../../store/index';
import Constants from "@/components/constants/Constants";
// axios.defaults.withCredentials = true;   //会引发跨域
axios.defaults.timeout=30000;
axios.interceptors.request.use(config=>{
    var data = config.data;
    var conf = config.headers;
    var token = store.state.token;
    // console.log("store token = "+token);
    if(!token) {
        token = window.localStorage.getItem("token");
    }
    // console.log("req token="+token);
    config.headers["token"] = token;
    return config;
}, error=> {
    return Promise.reject(error);
});
axios.interceptors.response.use(response=>{
    var token = response.headers["token"];
    // console.log("resp token="+token);
    if(token && token.trim() != "") {
        store.commit('setToken', token);
        window.localStorage.setItem('token', token);
    }
    return response;
}, error => {
    return Promise.reject(error);
});
var HOST_URL = Constants.HOST_URL;
function INIT_BASE_URL(type) {
    if(type == 1) {
        axios.defaults.baseURL = HOST_URL + "/";
    } else {
        axios.defaults.baseURL = HOST_URL + "/";
    }
}
export default class HttpUtils {
    static GET(url, params={}) {
        INIT_BASE_URL(1);
        return new Promise((resolve, reject) => {
            axios.get(url, {
                params: params
            }).then(response=>{
                resolve(response.data);
            }).catch(error=>{
                reject(error);
            });
        });
    }
    static POST(url, params={}) {
        INIT_BASE_URL(1);
        return new Promise((resolve, reject)=> {
            axios.post(url, params).then(response=>{
                if(response.data.code == "8401") {
                    store.commit('setToken', "");
                    window.localStorage.setItem('token', "");
                    window.location.href = "/login";
                    return;
                }
                resolve(response.data);
            }).catch(error=>{
                console.log(error);
                reject(error);
            });
        });
    }
    static POST_FORM(url, params={}) {
        INIT_BASE_URL(1);
        return new Promise((resolve, reject)=> {
            axios.postForm(url, params).then(response=>{
                if(response.data.code == "8401") {
                    store.commit('setToken', "");
                    window.localStorage.setItem('token', "");
                    window.location.href = "/login";
                    return;
                }
                resolve(response.data);
            }).catch(error=>{
                console.log(error);
                reject(error);
            });
        });
    }
    static POST_API(url, params={}) {
        INIT_BASE_URL(1);
        return new Promise((resolve, reject)=> {
            axios.post(url, params).then(response=>{
                resolve(response.data);
            }).catch(error=>{
                console.log(error);
                reject(error);
            });
        });
    }
    static GET_USER_INFO() {
        var token = window.localStorage.getItem("token");
        let userString = decodeURIComponent(escape(window.atob(token.split('.')[1])))
        let user = JSON.parse(userString);
        return user;
    }
}